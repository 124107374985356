import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import SearchSuggestion from 'components/Common/SearchSuggestion';
import { staticMediaStoreBaseURL } from 'config/common';
import { getSuggestions } from 'services/search.service';

export default function CustomSearchBox({
  searchedValue = '',
  onScrolling,
  homeScreen
}) {
  const Router = useRouter();
  const [searchValue, setSearchValue] = useState(searchedValue);
  const [suggestions, setSuggestions] = useState([]);
  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
    const response = await getSuggestions(e.target.value);
    setSuggestions([]);
    if (response.status && response.entity.count) {
      setSuggestions(response.entity.hits);
    }
  };
  useEffect(() => {
    setSearchValue(searchedValue);
  }, [searchedValue]);

  const selectingSuggestion = (suggestion) => {
    setSearchValue(suggestion);
    setSuggestions([]);
    Router.push(`/search-result/${suggestion}`);
  };

  return (
    <div className='py-6'>
      <div
        className={`relative mx-5 h-5 bg-white flex flex-row-reverse items-center md:rounded-full ${
          homeScreen && onScrolling && 'md:border md:border-platinum'
        } ${
          !homeScreen && 'md:border md:border-platinum'
        } w-full md:w-88 lg-l:w-112 xl:w-150 md:h-11`}
      >
        <input
          className='w-full appearance-none transition bg:white text-10px md:text-sm text-nero placeholder-default
          leading-5 focus:bg-transparent hover:bg-transparent active:bg-transparent focus:outline-none md:rounded-full md:mx-0.5'
          id='search'
          type='text'
          placeholder='Search furniture, bouncy castles, birthday packages, artists &amp; more'
          autoComplete='off'
          value={searchValue}
          onChange={handleSearch}
          onFocus={(e) => {
            e.target.value && handleSearch(e);
          }}
          onKeyDown={(e) => {
            if (e.which === 13 && e.target.value.length) {
              selectingSuggestion(searchValue);
            }
          }}
        />
        <div className='px-2 md:px-2'>
          {searchValue.length ? (
            <div
              className='ml-2 md:pr-2'
              onClick={() => {
                setSearchValue('');
                setSuggestions([]);
              }}
            >
              <Image
                src={`${staticMediaStoreBaseURL}/icons/close-gray.svg`}
                alt='Close'
                height={12}
                width={12}
              />
            </div>
          ) : (
            <div className='md:w-9 md:h-9 md:max-auto md:flex md:items-center md:justify-center md:outline-none md:focus:outline-none'>
              <Image
                src={`${staticMediaStoreBaseURL}/icons/search_new.svg`}
                alt='Search'
                height={15}
                width={15}
              />
            </div>
          )}
        </div>
        {Boolean(suggestions.length) && (
          <>
            {' '}
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (e.target.id === 'modal') {
                  return setSuggestions([]);
                }
                return true;
              }}
              id='modal'
              className='fixed z-20 w-full h-full inset-0 flex md:items-center items-end'
            ></div>
            <SearchSuggestion
              {...{ suggestions, selectingSuggestion, searchValue }}
            />
          </>
        )}
      </div>
    </div>
  );
}
