import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { staticMediaStoreBaseURL } from 'config/common';
import { captureGTMEventLogOut } from 'lib/gtm';
import { getAgentInfo } from 'services/identity.service';

const ProfileMenuItem = () => (
  <Link href='/user-profile'>
    <span {...{ className: 'ps-2 py-1 text-dim-gray cursor-pointer' }}>
      Profile
    </span>
  </Link>
);

const MyEventsMenuItem = () => (
  <Link href='/user-profile/my-events'>
    <span {...{ className: 'ps-2 py-1 text-dim-gray cursor-pointer' }}>
      My Events
    </span>
  </Link>
);

const OrdersMenuItem = () => (
  <Link href='/my-orders'>
    <span {...{ className: 'ps-2 py-1 text-dim-gray cursor-pointer' }}>
      My Orders
    </span>
  </Link>
);

const LogOutMenuItem = ({ logOut }) => (
  <span
    {...{
      className: 'ps-2 py-1 text-dim-gray cursor-pointer',
      onClick: () => {
        captureGTMEventLogOut();
        logOut();
      }
    }}
  >
    {' '}
    Log Out
  </span>
);

const UserName = ({ name, isScrolling }) => (
  <span
    className={` ${
      isScrolling ? 'text-white' : 'text-brand'
    } ms-2 text-sm w-15 overflow-hidden font-medium relative inline-block no-underline overflow-ellipsis whitespace-nowrap`}
  >
    {name || ''}
  </span>
);

const AccountMenu = ({ logOut, onHomeScreenBanner, ...context }) => {
  const [showAccountOptions, setShowAccountOptions] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});
  const { agent } = agentInfo;
  const title =
    agent &&
    `Username: ${agent.name}\nUser ID: ${agentInfo.id}\nAgent: ${agent.email}`;

  const icon = `${staticMediaStoreBaseURL}/icons/${
    onHomeScreenBanner ? 'profile-icon.svg' : 'profile-scroll.svg'
  }`;
  useEffect(() => {
    setAgentInfo(getAgentInfo());
  }, []);
  const DropdownMenu = () => (
    <>
      <div
        {...{
          onClick: (e) => {
            e.stopPropagation();
            if (e.target.id === 'modal') {
              setShowAccountOptions(false);
            }
          },
          id: 'modal',
          className:
            'fixed z-40 w-full h-full inset-0 flex md:items-center items-end'
        }}
      ></div>
      <div
        {...{
          className:
            'border border-platinum rounded-lg flex flex-col absolute z-50 top-10 w-36 bg-white p-2 mt-1'
        }}
      >
        <ProfileMenuItem />
        <MyEventsMenuItem />
        <OrdersMenuItem />
        <LogOutMenuItem {...{ ...context, logOut }} />
      </div>
    </>
  );

  return (
    <div
      {...{
        title,
        className:
          'relative flex items-center justify-center cursor-pointer text-nero rounded md:rounded-6.5 px-2',
        onClick: () => setShowAccountOptions((prevState) => !prevState)
      }}
    >
      <div
        {...{
          className: `flex items-center justify-center ${
            onHomeScreenBanner ? 'text-white' : 'text-brand'
          }`
        }}
      >
        <Image
          {...{
            className: 'cursor-pointer header-icon-size',
            src: icon,
            layout: 'fixed',
            width: '33',
            height: '33',
            objectFit: 'contain',
            alt: 'Login'
          }}
        />
        <div className='mt-1 leading-4'>
          <span
            className={` ${
              onHomeScreenBanner ? 'text-white' : 'text-brand'
            } ms-2 min-w-14 text-sm font-medium`}
          >
            Hello,
            <br />
          </span>
          <UserName
            name={context.authUser.name}
            isScrolling={onHomeScreenBanner}
          />
        </div>
      </div>
      <div className='mt-4'>
        <Image
          alt='downIcon'
          width={15}
          height={15}
          layout='fixed'
          src={`${staticMediaStoreBaseURL}/icons/${
            onHomeScreenBanner ? 'down-arrow-white.svg' : 'down-orange.svg'
          }`}
          className={`self-center mb-3.5 ${
            showAccountOptions ? 'transform rotate-180' : 'transform rotate-0'
          }`}
        />
      </div>
      {showAccountOptions && <DropdownMenu />}
    </div>
  );
};

export default AccountMenu;
