import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useScrollData } from 'scroll-data-hook';

import SearchModal from 'components/Common/SearchModal';
import CallIcon from 'components/Common/header/CallIcon';
import CartView from 'components/Common/header/CartView';
import CustomSearchBox from 'components/Common/header/CustomSearchBox';
import HaflaLogoMobile from 'components/Common/header/Header/HaflaLogoMobile';
import HaflaLogoWeb from 'components/Common/header/Header/HaflaLogoWeb';
import HomeSearchBox from 'components/Common/header/Header/HomeSearchBox';
import LoginControls from 'components/Common/header/Header/LoginControls';
import SearchBox from 'components/Common/header/Header/SearchBox';
import SearchIcon from 'components/Common/header/Header/SearchIcon';
import shouldUpdateTimeZone from 'components/Common/header/Header/service';
import WhatsappSupportIcon from 'components/Common/header/WhatsappSupportIcon';
import HeaderContainer from 'components/Common/header/web/HeaderContainer';
import { useUIContext } from 'components/context/uiContext/ManagedUiContext';
import { useIsMobile } from 'helpers/screenResolutionHelper';
import { getSelectedCart } from 'services/cart.service';
import { getCartDetailById } from 'services/hostCart.service';
import { isAuthenticated } from 'services/identity.service';

export default function Header({
  categories,
  categoriesWithSubCategories,
  doNotShowWhatsApp,
  heading = '',
  homeScreen = false,
  parentSlug,
  searchedValue = '',
  searchResultPage = false,
  showSearchIcon = true,
  showCartView = true,
  showHeaderEventList
}) {
  const [logoUrl, setLogoUrl] = useState(
    homeScreen ? '/logos/hafla-white.svg' : '/logos/hafla_new.svg'
  );

  const [isMobile] = useIsMobile();

  const Router = useRouter();

  const handleBack = () => Router.back();

  const uiContext = useUIContext();
  const {
    cartItemCount,
    cartList,
    defaultCart,
    selectedCart,
    updateAndSetUserTimeZone,
    updateCartInPortalHeader
  } = uiContext;

  const [showSearchModal, setShowSearchModal] = useState(false);

  const pathName = Router.asPath;

  const [onScrolling, setOnScrolling] = useState(false);
  const { position } = useScrollData({});
  const [showCartSelectorModal, setShowCartSelectorModal] = useState(false);
  const onScrollNotInHomeScreen = !homeScreen || onScrolling;
  const onHomeScreenBanner = homeScreen && !onScrolling;

  const isGuestUser = !isAuthenticated();
  const updateTimeZone = shouldUpdateTimeZone();

  const currentSelectedCart = getSelectedCart();

  const updateCartItemCount = async () => {
    const { entity } = await getCartDetailById({
      cartId: currentSelectedCart.id
    });
    entity && updateCartInPortalHeader(entity);
  };

  useEffect(() => {
    currentSelectedCart && updateCartItemCount();
  }, []);

  useEffect(() => {
    if (homeScreen) {
      // TODO: Hardcoded y value (477) will not work on portfolio views in tablets.
      if (position.y > 477 && !onScrolling) {
        setLogoUrl('/logos/hafla_new.svg');
        setOnScrolling(true);
      } else if (position.y < 477 && onScrolling) {
        setLogoUrl('/logos/hafla-white.svg');
        setOnScrolling(false);
      }
    }
  }, [position.y, onScrolling, homeScreen]);

  useEffect(() => {
    if (!isGuestUser && updateTimeZone) {
      updateAndSetUserTimeZone();
    }
  }, [isGuestUser, updateTimeZone]);

  // TODO: Faizan incorrect name "HeaderContainer"  -- it is "MegaMenu" so it should have been named it that way
  // TODO: MegaMenu is not part of all pages, where as Header is part of most of the pages, this shouldn't be inside Header.js it is used only on homepage and in CLPs
  return (
    <>
      <HeaderContainer
        {...{
          categoriesWithSubCategories,
          categories,
          parentSlug,
          homeScreen,
          onScrolling,
          showHeaderEventList
        }}
      >
        <div className='md:flex hidden items-center w-3/5'>
          <HaflaLogoWeb {...{ logoUrl }} />

          {onScrollNotInHomeScreen && (
            <div className='w-3/5 hidden md:block md:me-6 lg:me-8'>
              <CustomSearchBox
                searchedValue={searchedValue}
                onScrolling={onScrolling}
                homeScreen={homeScreen}
                searchResultPage={searchResultPage}
              />
            </div>
          )}
        </div>

        {!homeScreen && (
          <SearchBox
            {...{
              handleBack,
              searchResultPage,
              setShowSearchModal,
              heading,
              searchedValue
            }}
          />
        )}

        {homeScreen && isMobile && <HaflaLogoMobile {...{ logoUrl }} />}

        <div className='flex justify-end items-center max-w-max md:max-w-22 w-full'>
          <CallIcon {...{ onScrollNotInHomeScreen }} />

          {!doNotShowWhatsApp && <WhatsappSupportIcon />}

          <LoginControls
            {...{
              ...uiContext,
              isMobile,
              onHomeScreenBanner,
              onScrollNotInHomeScreen,
              pathName
            }}
          />

          {onScrollNotInHomeScreen && showSearchIcon && (
            <>
              <SearchIcon {...{ setShowSearchModal }} />
              {showSearchModal && (
                <SearchModal
                  setShowSearchModal={setShowSearchModal}
                  searchedValue={searchedValue}
                />
              )}
            </>
          )}

          {onHomeScreenBanner && (
            <HomeSearchBox
              {...{
                setShowSearchModal,
                showSearchModal,
                searchedValue
              }}
            />
          )}

          {showCartView && (
            <CartView
              {...{
                cartItemCount,
                cartList,
                defaultCart,
                onHomeScreenBanner,
                Router,
                selectedCart,
                setShowCartSelectorModal,
                showCartSelectorModal
              }}
            />
          )}
        </div>
      </HeaderContainer>
    </>
  );
}
